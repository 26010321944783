<template>
  <div id="AccessRecords">
    <CSBreadcrumb/>
    <div class="filter-panel">
      <div
          class="cs-select"
          style="
            margin-right: 10px;
            vertical-align: middle;
            width: 230px;
          "
        >
          <el-date-picker
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            placeholder="请选择开始时间"
            v-model="queryAccessRecordsInfo.startDate"
            :editable="false"
          >
          </el-date-picker>
          <i class="icon el-icon-caret-bottom" style="width: 26px"></i>
        </div>
        <span
          style="
            display: inline-block;
            vertical-align: middle;
            height: 2px;
            width: 20px;
            margin-right: 0;
            background-color: #999;
          "
        >
        </span>
        <div
          class="cs-select"
          style="
            margin-left: 10px;
            vertical-align: middle;
            margin-right: 20px;
            width: 230px;
          "
        >
          <el-date-picker
            format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="请选择结束时间"
            v-model="queryAccessRecordsInfo.endDate"
            :editable="false"
          >
          </el-date-picker>
          <i class="icon el-icon-caret-bottom" style="width: 26px"></i>
        </div>
        <CSSelect
          height="30px"
        >
          <select
            style="width: 140px"
            v-model="queryAccessRecordsInfo.userId"
          >
            <option value="">巡检人不限</option>
            <option
              v-for="staff in staffList"
              :key="staff.id"
              :value="staff.id"
            >{{ staff.name }}</option>
          </select>
        </CSSelect>
        <button
          type="button"
          class="btn btn-primary"
          style="margin-left: 20px; font-size: 14px; height: 30px; padding: 0; width: 50px"
          @click="getAccessRecords()"
        >
          查询
        </button>
    </div>
    <div class="result-panel">
        <CSTable :thead-top="filterHeight">
            <template v-slot:thead>
                <tr class="head_row">
                    <th>巡检时间</th>
                    <th>签到时间</th>
                    <th>检查项</th>
                    <th>巡检人</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="item in accessRecordsInfo"
                    :key="item.pointLogId"
                    style="line-height: 50px"
                >
                    <td class="center full-date-td">
                        {{ item.inspectionTime }}
                    </td>
                    <td class="center full-date-td">
                        {{ item.signTime || "-" }}
                    </td>
                    <td class="center">
            <span
                class="allow-click"
                @click="lookCheckItemsInfo(item.pointLogId)"
            >
              {{ item.checkItemNum }}
            </span>
                    </td>
                    <td class="center">
                        {{ item.userName }}
                    </td>
                </tr>
            </template>
        </CSTable>
    </div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSSelect from "@/components/common/CSSelect";
import dayjs from "dayjs";
import {
  queryDepartmentStaffUrl,
  adminQueryInspectLogUrl,
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
  name: "AccessRecords",
  props: {
    locationId: Number,
  },
  components: {
      CSTable,
    CSBreadcrumb,
    CSSelect,
  },
  data() {
    return {
      filterHeight: 0,
      queryAccessRecordsInfo: {
        regionCode: "",                  //区域code  必填
        locationId: "",                   //巡检点id  必填
        pageSize: 50,                    //默认10
        startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD") + " 00:00",                   //查询开始时间
        endDate: dayjs().format("YYYY-MM-DD") + " 23:59",                     //结束时间
        userId: "",                      //巡检人id
      },
      // 员工列表
      staffList: [],
      // 访问记录
      accessRecordsInfo: [],
    }
  },
  created() {
    const departmentCode = this.$vc.getCurrentStaffInfo().departmentCode;
    this.getStaffList(departmentCode);
    // 获取访问记录
    this.getAccessRecords();
  },
    mounted() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
  methods: {
    // 获取员工列表
    async getStaffList(departmentCode) {
      let res = await this.$fly
          .post(queryDepartmentStaffUrl, {
            regionCode: this.$vc.getCurrentRegion().code || "",
            departmentCode,
            jobLevel: "",
            isOnTheJob: true,
          })
      if (res.code != 0) {
        return;
      }
      this.staffList = res.data;
    },
    // 获取访问记录
    async getAccessRecords() {
        let params = {
          ...this.queryAccessRecordsInfo,
          regionCode: this.$vc.getCurrentRegion().code,
          locationId: this.locationId,
        };
        let res = await this.$fly
            .post(adminQueryInspectLogUrl, params)
        if (res.code != 0) {
          return;
        }
        this.accessRecordsInfo = res.data || [];
    },
    lookCheckItemsInfo(pointLogId) {
      this.$router.push({name: "Checkeditem", params: {pointLogId}})
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
